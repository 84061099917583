<template>
  <v-container fluid class="pa-0 rai-txt rai-txt--mobile">
    <v-row no-gutters>
      <v-col cols="12" class="rai-texting-list">
        <slot name="list" />
        <slot v-if="$route.path !== '/texting'" name="detail" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TextingViewMobile",
};
</script>
